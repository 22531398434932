<template>
  <card class="card card-animation-on-hover-no-bg"
        :class="textAlign"
        :style="{background: color}"
        style="z-index: 1">
    <div
      class="card-header">
      <div class="card-title">
        <label v-if="this.label" class="btn-primary"
               style="position:absolute;top: 100px;right:40px;border-radius: 10px;padding: 3px;font-weight: 600;vertical-align: auto">
          ● {{ this.label }}</label>
        <a :href="this.link" v-if="this.link">
          <img v-lazy="`${this.image}?w=${this.width}&h=${this.height}&fit=fillmax&fill=blur`"
               :width="`${this.width}px`"
               :alt="this.alt || this.title"
               :height="`${this.height}px`">
        </a>
        <img v-lazy="`${this.image}?w=${this.width}&h=${this.height}&fit=fillmax&fill=blur`"
             :width="`${this.width}px`"
             :alt="this.alt"
             v-if="!this.link"
             :height="`${this.height}px`">
        <h3 class="tulum-party-title" style="font-size: 1rem;margin-top: 7px;">{{ this.title }}</h3>
        <p style="margin-top: -20px;margin-bottom: 20px;font-size: 0.8rem;font-weight: lighter">{{ this.subTitle }}</p>
      </div>
    </div>
    <div class="card-body">
      <div v-if="this.text" v-html="this.text"></div>
    </div>
    <div class="card-link text-center">
      <a v-if="this.link && this.actionLabel" :href="`${this.link}`"
         @click="clickLog"
         class="btn btn-primary btn-sm " style="margin-top: 15px">{{ this.actionLabel }}</a>
    </div>
  </card>
</template>
<script>
export default {
  name: 'img-card',
  props: {
    title: {
      type: String,
      description: 'Card title',
      default: ''
    },
    alt: {
      type: String,
      description: 'Card title',
      default: 'Tulum Party'
    },
    subTitle: {
      type: String,
      description: 'Card subtitle',
      default: ''
    },
    label: {
      type: String,
      description: 'Card label',
      default: ''
    },
    text: {
      type: String,
      description: 'Card subtitle',
      default: undefined
    },
    align: {
      type: String,
      default: 'left',
      description: 'Text Alignment',
    },
    link: {
      type: String,
      description: 'Link on Action',
      default: ''
    },
    color: {
      type: String,
      description: 'Card Color',
      default: '#1a1818'
    },
    action: {
      type: String,
      description: 'Action',
      default: ''
    },
    actionLabel: {
      type: String,
      description: 'Card subtitle',
    },
    image: {
      type: String,
      description: 'Card subtitle',
    },
    width: {
      type: String,
      description: 'Card subtitle',
      default: '300'
    },
    height: {
      type: String,
      description: 'Card subtitle',
      default: '300'
    },
  },
  methods: {
    clickLog() {
      this.$rollbar.info(`Img Card Click: ${this.title}`);
    },
  },
  computed: {
    textAlign() {
      return 'text-' + this.align;
    },
  },
};
</script>
